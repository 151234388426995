.flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ui-submit-btn {
    cursor: pointer;
    padding: 8px 26px;
    text-align: center;
    /* border: 2px solid black; */
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: rgb(234, 85, 5);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.ui-submit-btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.ui-submit-play-btn {
    cursor: pointer;
    padding: 8px 26px;
    text-align: center;
    /* border: 2px solid black; */
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: #FAB626;
}

.ui-submit-play-btn:hover {
    color: #FAB626;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.verify-email-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #EAEAEA;
    padding: 16px 30px;
    margin: 10px 0px;
    border-radius: 6px;
    margin-top: 15px;
    font-size: 14px;
}

.social-icons-failed {
    display: flex;
    flex-direction: row;
}


.ui-change-wallet-play-btn {
    cursor: pointer;
    padding: 8px 18px;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: #FAB626;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px; */
}

.ui-change-wallet-play-btn:hover {
    color: #FAB626;
    background-color: #fff;
}

.ui-change-wallet-btn {
    cursor: pointer;
    padding: 8px 18px;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: rgb(234, 85, 5);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.ui-change-wallet-btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.ui-click-here-btn {
    cursor: pointer;
    padding: 8px 54px;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    font-size: 24px;
    background-color: rgb(234, 85, 5);
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.ui-click-here-btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.ui-click-here-btn:hover .ui-arrow {
    background-color: #ea5505;
}

.ui-arrow {
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-image: url('../assets/do-to-win/arrow.svg');
    mask-image: url('../assets/do-to-win/arrow.svg');
    background-color: white;
    /* Or any color of your choice. */
    width: 1em;
    height: 1em;
    margin-right: 6px;
    transition: 1s;
}

.connect-ui-submit-play-btn {
    cursor: pointer;
    padding: 4px 26px;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: #FAB626;
    padding: 20px 40px;
    display: inline-flex;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.connect-ui-submit-play-btn:hover {
    color: #FAB626;
    background-color: #fff;
}

.connect-ui-submit-play-btn>svg {
    height: 17.5px;
}

.connect-ui-submit-play-btn>svg>path {
    transition: 1s;
}

.connect-ui-submit-play-btn:hover>svg>path {
    fill: #FAB626;
}

.play-connected-svg>circle {
    fill: #F8B629;
}

.giveaway-list-btn {
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: rgb(127, 203, 63);
    padding: 6px 15px;
    display: inline-flex;
    flex-direction: row;
    font-size: 14px;
    width: 96px;
    justify-content: center;
}

.giveaway-list-btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.giveaway-list-btn-disabled {
    cursor: not-allowed;
}

.giveaway-list-btn-ended {
    background-color: rgb(109, 109, 109);
}

.giveaway-list-btn-sign-up {
    background-color: rgb(230, 47, 40);
}

/* .giveaway-list-btn-disabled:hover {
    transition: none;
} */

.page-list-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
}

.giveaway-item {
    font-size: 1.17em;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.giveaway-page-list-item-btn {
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    border: 1px solid rgb(222, 63, 40);
    color: rgb(222, 63, 40);
    transition: 1s;
    font-family: google_sansbold;
    background-color: #fff;
    padding: 6px 15px;
    display: inline-flex;
    flex-direction: row;
    font-size: 14px;
    margin: 0px 5px;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.giveaway-page-list-item-btn-selected {
    color: #fff;
    background-color: rgb(222, 63, 40);
}

.giveaway-page-list-item-btn-disabled {
    box-shadow: none;
    cursor: not-allowed;
}

.giveaway-page-list-item-btn:hover {
    color: #fff;
    background-color: rgb(222, 63, 40);
}

.giveaway-page-list-item-btn-disabled:hover {
    color: rgb(222, 63, 40);
    background-color: #fff;
}

.connect-ui-submit-btn {
    cursor: pointer;
    padding: 4px 26px;
    text-align: center;
    border-radius: 5px;
    color: rgb(255, 255, 255);
    transition: 1s;
    font-family: google_sansbold;
    background-color: rgb(234, 85, 5);
    padding: 20px 40px;
    display: inline-flex;
    flex-direction: row;
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
}

.connect-ui-submit-btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.connect-ui-submit-btn>svg {
    height: 17.5px;
}

.connect-ui-submit-btn>svg>path {
    transition: 1s;
}

.connect-ui-submit-btn:hover>svg>path {
    fill: #EA5505;
}

.background-container {
    /* position: absolute;
    bottom: 0;
    left: 0;
    right: 0; */
}

.background-container>img {
    width: 100%;
    position: relative;
    top: 10px;
}

.verify-email {
    border: 2px solid red;
    /* background: black; */
    color: red;
    width: 100%;
    text-align: center;
    font-size: 24px;
    padding: 5px;
    font-weight: bold;
    margin-top: 5px;
}

.close-btn-popup-modal {
    position: absolute;
    top: 100px;
    right: 10px;
}

@media only screen and (max-width: 388px) {
    .ui-click-here-btn {
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .verify-email {
        width: 100%;
    }

    .social-icons-failed {
        flex-direction: column;
    }

    .social-icons-failed>span {
        margin-bottom: 10px;
    }
}

@media only screen and (min-width: 601px) {
    .verify-email {
        width: 50%;
    }
}

@font-face {
    font-family: 'google_sansbold';
    src: url('./googlesans-bold-webfont.eot');
    src: url('./googlesans-bold-webfont.woff2') format('woff2'),
        url('./googlesans-bold-webfont.woff') format('woff'),
        url('./googlesans-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

span>a.bold-link {
    color: #EA5505;
    font-family: product_sans_bold;
    text-decoration: none;
}

@font-face {
    font-family: 'product_sans';
    src: url('./product-sans/Product\ Sans\ Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'product_sans_bold';
    src: url('./product-sans/Product\ Sans\ Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'exo';
    src: url('./exo-font/Exo-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'exo_bold';
    src: url('./exo-font/Exo-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

/*HEADER*/

.add_shadow{ 
    box-shadow: 10px 0 45px rgb(0 0 0 / 10%); 
}

.header_outer {
    width: 100%;
    padding: 15px 0;
    background-color: #fff;
}

.hddr_inr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hdr_menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.hdr_menu ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
}

.hdr_menu ul li {
    margin: 0 10px;
    position: relative;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    text-decoration: none !important;
    font-style: normal;
}

.hdr_menu ul li:last-child {
    margin-right: 0;
}

.hdr_menu ul li:first-child {
    margin-left: 0;
}

.hdr_menu ul li:first-child {
    margin-left: 0;
}

.hdr_menu ul li a {
    display: block;
    font-size: 18px;
    color: #3e3a39;
    font-family: 'google_sansbold';
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    text-decoration: none !important;
    font-style: normal;
}

.hdr_menu ul li a:hover,
.hdr_menu ul li a:focus {
    color: #ea5505;
}

.hdr_menu ul li.menu-item-25 {
    display: none;
}

/* .hdr_menu ul li.menu-item-248 {display: none;} */


.solid_btn {
    background-color: #ea5505;
    border-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 30px;
    border: 1px solid;
    -webkit-transition: 1s;
    transition: 1s;
    font-family: 'google_sansbold';
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    text-decoration: none !important;
    font-style: normal;
}

.hdr_menu li {
    position: relative;
}

.hdr_menu li::before {
    position: absolute;
    content: "";
    left: -6px;
    bottom: 0px;
    height: 4px;
    width: 100%;
    background-color: #ea5505;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: left;
    transform-origin: left;
    margin-left: 6px;
    -webkit-transition: -webkit-transform .4s linear;
    transition: -webkit-transform .4s linear;
    transition: transform .4s linear;
    transition: transform .4s linear, -webkit-transform .4s linear;
}

.hdr_menu li:hover::before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left;
    transform-origin: left;
}


.solid_btn:hover {
    color: #ea5505;
    background-color: #fff;
}

.solid_btn:hover:after {
    height: 100%;
}

.hdr_menu .solid_btn {
    margin-left: 20px;
}

.hdr_menu #header_join.solid_btn {
    display: none;
}

.hdr_menu #header_login.solid_btn {
    color: #ffffff;
    background-color: #f8b62a;
}

.hdr_menu #header_login.solid_btn:hover {
    color: #f8b62a;
    background-color: #fff;
}

.hddr_inr .navbar-nav li {
    margin: 0 10px;
}

.hddr_inr .navbar-nav li a {
    display: block;
    font-size: 16px;
    color: #fff;
}

.hddr_inr .navbar-nav li a:hover,
.hddr_inr .navbar-nav li a:focus {
    color: #ff4081;
}

.hddr_inr .navbar-collapse .solid_btn {
    margin-left: 20px;
}

.hddr_inr .navbar {
    padding: 0;
}

.logo {
    padding: 0;
}

@media (max-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (max-width: 992px) {
    .container {
        max-width: 960px;
    }
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.hdr_menu #header_login {
    display: none;
}

@media (max-width: 991px) {

    /*header*/
    .hdr_menu li::before {
        bottom: 0;
    }

    .hdr_menu #header_login {
        display: inherit;
    }

    /* mobile menu start */
    .hdr_menu {
        padding: 20px 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 270px;
        -webkit-transform: translateX(470px);
        transform: translateX(470px);
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        padding: 60px 15px 20px;
        z-index: 100;
        -webkit-box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
    }

    .hdr_menu ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .hdr_menu ul li {
        width: 100%;
        margin: 0;
    }

    .hdr_menu li a,
    .hdr_menu li.current-menu-item a {
        color: #000;
        padding: 10px 0;
    }

    .hdr_menu ul li a {
        color: #000;
    }

    .hdr_menu ul li.menu-item-25,
    .hdr_menu ul li.menu-item-248 {
        display: block;
    }

    .menu_btn {
        width: 35px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .menu_btn span {
        height: 2px;
        display: block;
        background: #000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
    }

    .menu_btn span+span {
        margin-top: 10px;
    }

    .addPnnl .menu_trigger {
        position: fixed;
        top: 15px;
        right: 17px;
        z-index: 999;
    }

    .addPnnl .menu_btn span {
        background-color: #0551c2;
    }

    .addPnnl .hdr_menu {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    .addPnnl .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.18);
        z-index: 30;
    }

    .addPnnl .menu_btn span:nth-child(3) {
        display: block;
    }

    .addPnnl .menu_btn {
        height: 35px;
    }

    .addPnnl .menu_btn span:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 16px;
    }

    .addPnnl .menu_btn span:last-child {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .addPnnl .menu_btn span:nth-child(2) {
        display: none;
    }

    .addPnnl .menu_btn span+span {
        margin-top: -10px;
    }

    /*
      .header_outer {
        padding-top: 18px;
      }
    */
    .hdr_menu {
        display: block;
    }

    .hdr_menu .solid_btn {
        margin: 20px 0 0 0;
        display: block;
    }

    .hdr_menu #header_join.solid_btn {
        display: block;
    }

    /*header(renewal)*/
    .renew_header_outer .hdr_menu li::before {
        bottom: 0;
    }

    /* mobile menu start */
    .renew_header_outer .hdr_menu {
        padding: 20px 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 270px;
        -webkit-transform: translateX(470px);
        transform: translateX(470px);
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        padding: 60px 15px 20px;
        z-index: 100;
        -webkit-box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
    }

    .renew_header_outer .hdr_menu ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .renew_header_outer .hdr_menu ul li {
        width: 100%;
        margin: 0;
    }

    .renew_header_outer .hdr_menu li a,
    .renew_header_outer .hdr_menu li.current-menu-item a {
        color: #000;
        padding: 10px 0;
    }

    .renew_header_outer .hdr_menu ul li a {
        color: #000;
    }

    .renew_header_outer .hdr_menu ul li.menu-item-25,
    .renew_header_outer .hdr_menu ul li.menu-item-248 {
        display: block;
    }

    .renew_header_outer .menu_btn {
        width: 35px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .renew_header_outer .menu_btn span {
        height: 2px;
        display: block;
        background: #000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
    }

    .renew_header_outer .menu_btn span+span {
        margin-top: 10px;
    }

    .addPnnl .menu_trigger {
        position: fixed;
        top: 15px;
        right: 17px;
        z-index: 999;
    }

    .addPnnl .menu_btn span {
        background-color: #0551c2;
    }

    .addPnnl .hdr_menu {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    .addPnnl .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.18);
        z-index: 30;
    }

    .addPnnl .menu_btn span:nth-child(3) {
        display: block;
    }

    .addPnnl .menu_btn {
        height: 35px;
    }

    .addPnnl .menu_btn span:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 16px;
    }

    .addPnnl .menu_btn span:last-child {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .addPnnl .menu_btn span:nth-child(2) {
        display: none;
    }

    .addPnnl .menu_btn span+span {
        margin-top: -10px;
    }

    .renew_header_outer .hdr_menu {
        display: block;
    }

    .renew_header_outer .hdr_menu .solid_btn {
        margin: 20px 0 0 0;
        display: block;
    }

    .renew_header_outer .hdr_menu #header_join.solid_btn {
        display: block;
    }
}

/* FOOTER */

/*footer*/
.footer_outer {
    background-color: #1a1817;
    padding: 200px 0 35px;
    position: relative;
    width: 100%;
}

.footer_top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}

.footer_top img {
    width: 100%;
}

.footer_blk h5 {
    font-size: 18px;
    line-height: 36px;
    color: #fff;
    padding-bottom: 30px;
}

.footer_blk ul {
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0;
}

.footer_blk ul li {
    font-size: 16px;
}

.footer_blk ul li a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none !important;
}

.footer_logo {
    padding-bottom: 30px;
}

.social_icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: -20px;
}

.social_icon li {
    border: 2px solid rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    margin-right: 8px;
    border-radius: 5px;
}

.footer_btn_wrap a:last-child {
    background-color: #ea5505;
    color: #fff;
}

.footer_btn_wrap a {
    background-color: #ea5505;
    width: 100%;
    margin-bottom: 15px;
    text-align: center;
    padding: 17px 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none!important;
    display: block;
}

.footer_btn_wrap a:hover {
    color: #fff;
}

.footer_btn_wrap a#footer_login {
    background-color: #f8b62a;
}

.footer_btn_wrap {
    margin-top: 40px;
}

.footer_outer .col-lg-2 {
    padding-left: 40px;
}

.bottom_footer {
    text-align: center;
    border-top: 2px solid rgba(242, 243, 248, 0.1);
    padding-top: 30px;
}

.bottom_footer span {
    color: rgba(248, 247, 252, 0.5);
}

.footer_inner {
    padding-bottom: 63px;
}

.footer_blk p {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    line-height: 26px;
}

.footer_inner .row .col-lg-3:last-child {
    padding-right: 0;
}

.footer_blk ul li {
    margin-bottom: 20px;
}

@media (max-width: 1199px) {
    .footer_outer {
        padding: 160px 0 35px;
    }

    /*    .footer_outer .col-md-2 { padding-left: 15px;}*/
    .footer_inner .row .col-md-3:last-child {
        padding-left: 0;
        margin-left: -20px;
    }

    .footer_btn_wrap a {
        font-size: 13px;
    }
}

@media (max-width: 991px) {

    /*header*/
    .hdr_menu li::before {
        bottom: 0;
    }

    /* mobile menu start */
    .hdr_menu {
        padding: 20px 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 270px;
        -webkit-transform: translateX(470px);
        transform: translateX(470px);
        background-color: rgba(255, 255, 255, 1);
        overflow-y: auto;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        padding: 60px 15px 20px;
        z-index: 100;
        -webkit-box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 170px rgba(0, 0, 0, 0.6);
    }

    .hdr_menu ul {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .hdr_menu ul li {
        width: 100%;
        margin: 0;
    }

    .hdr_menu li a,
    .hdr_menu li.current-menu-item a {
        color: #000;
        padding: 10px 0;
    }

    .hdr_menu ul li a {
        color: #000;
    }

    .hdr_menu ul li.menu-item-25,
    .hdr_menu ul li.menu-item-248 {
        display: block;
    }

    .menu_btn {
        width: 35px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }

    .menu_btn span {
        height: 2px;
        display: block;
        background: #000;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        width: 100%;
    }

    .menu_btn span+span {
        margin-top: 10px;
    }

    .addPnnl .menu_trigger {
        position: fixed;
        top: 15px;
        right: 17px;
        z-index: 999;
    }

    .addPnnl .menu_btn span {
        background-color: #0551c2;
    }

    .addPnnl .hdr_menu {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    .addPnnl .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.18);
        z-index: 30;
    }

    .addPnnl .menu_btn span:nth-child(3) {
        display: block;
    }

    .addPnnl .menu_btn {
        height: 35px;
    }

    .addPnnl .menu_btn span:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 16px;
    }

    .addPnnl .menu_btn span:last-child {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .addPnnl .menu_btn span:nth-child(2) {
        display: none;
    }

    .addPnnl .menu_btn span+span {
        margin-top: -10px;
    }

    .hdr_menu {
        display: block;
    }

    .hdr_menu .solid_btn {
        margin: 20px 0 0 0;
        display: block;
    }

    .hdr_menu #header_join.solid_btn {
        display: block;
    }

    .addPnnl .menu_trigger {
        position: fixed;
        top: 15px;
        right: 17px;
        z-index: 999;
    }

    .addPnnl .menu_btn span {
        background-color: #0551c2;
    }

    .addPnnl .hdr_menu {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }

    .addPnnl .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.18);
        z-index: 30;
    }

    .addPnnl .menu_btn span:nth-child(3) {
        display: block;
    }
    
    .addPnnl .menu_btn {
        height: 35px;
    }

    .addPnnl .menu_btn span:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin-top: 16px;
    }

    .addPnnl .menu_btn span:last-child {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .addPnnl .menu_btn span:nth-child(2) {
        display: none;
    }

    .addPnnl .menu_btn span+span {
        margin-top: -10px;
    }

    /*footer*/
    .footer_inner .col-md-12 {
        text-align: center;
    }

    .footer_btn_wrap {
        width: 300px;
        margin: 0 auto;
    }

    .social_icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .footer_blk ul li a {
        font-size: 15px;
    }

    .footer_blk p {
        font-size: 15px;
    }

    .footer_inner {
        padding-bottom: 40px;
    }

    .social_icon {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 10px;
      }
}

@media (max-width: 767px) {

    /*footer*/
    .footer_inner .col-12 {
        text-align: center;
        margin-bottom: 30px;
    }

    .footer_blk p {
        max-width: 400px;
        display: inline-block;
    }

    .footer_logo {
        padding-bottom: 15px;
    }

    .footer_blk h5 {
        padding-bottom: 20px;
        line-height: 23px;
    }

    .footer_blk ul li {
        margin-bottom: 10px;
    }

    .footer_inner .col-md-12 {
        padding-top: 25px;
    }

    .footer_outer {
        padding: 120px 0 35px;
    }

    .footer_inner {
        padding-bottom: 40px;
    }
}

@media (max-width: 480px) {

    .menu_btn span+span {
        margin-top: 8px;
    }

    .header_outer {
        padding: 12px 0;
    }

    /*footer*/
    .footer_outer .col-lg-2 {
        padding-left: 15px;
    }

    .footer_btn_wrap {
        width: 250px;
    }

    .footer_btn_wrap a {
        padding: 14px 0;
    }

    .footer_inner .row .col-lg-3:last-child {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (max-width: 420px) {

    /*footer*/
    .footer_blk ul li a {
        font-size: 14px;
    }

    .footer_blk p {
        font-size: 14px;
        line-height: 22px;
    }
}

#mainvisual {
    position: relative;
    margin-bottom: 100px;
    overflow-x: hidden;
    height: 700px;
    margin-bottom: 30px;
    font-family: exo;
}

#mainvisual h1{
    font-family: exo_bold;
    font-size: 72px;
}

#mainvisual::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 757px;
    height: 100%;
    background: url('../assets/do-to-win/bg_mv_pc.png') no-repeat left top;
    background-size: cover;
    z-index: 1;
}


#mainvisual .mainvisual-inner {
    width: 100%;
    max-width: 1320px;
    padding: 0 20px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

#mainvisual .mainvisual-inner .mainvisual-text-area {
    width: 100%;
    max-width: 600px;
    padding-top: 120px;
}

#mainvisual .mainvisual-text-area #logo {
    width: 100%;
    max-width: 213px;
    margin-bottom: 20px;
}

#mainvisual .mainvisual-text-area .block-lead-text {
    color: #313131;
    font-size: 28px;
    margin-top: 24px;
}

#mainvisual .mainvisual-text-area .block-lead-text .block-sub-lead-text {
    color: #B2B7C1;
    font-size: 18px;
}

#mainvisual .mainvisual-text-area .btn-signup {
    max-width: 335px;
    margin-top: 80px;
}

@media (max-width: 1101px) {
    #mainvisual {
        margin-bottom: 30px;
        height: 530px;
    }
    #mainvisual::after {
        width: 550px;
    }
    #mainvisual .mainvisual-inner .mainvisual-text-area {
        width: 100%;
        max-width: 550px;
        padding-top: 80px;
    }

    #mainvisual .mainvisual-text-area #logo {
        width: 100%;
        max-width: 190px;
        margin-bottom: 15px;
    }
    #mainvisual .mainvisual-text-area .block-lead-text {
        font-size: 20px;
    }

    #mainvisual .mainvisual-text-area .block-lead-text .block-sub-lead-text {
        font-size: 14px;
    }

    #mainvisual .mainvisual-text-area .btn-signup {
        /* max-width: 335px; */
        margin-top: 50px;
    }

    .block-conditions .block-title {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 24px;
    }
}


.section-inner {
    width: 100%;
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    color: #68717b;
    font-family: exo;
}

.section-inner > * + * {
    margin-top: 20px;
}

.box-grid {
    display: grid;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    gap: 20px;
}

.box-grid > *:first-child {
    grid-row: 1 / 3;
}

.btn-signup {
    
}
.btn-signup > a{
    color: #ffffff;
    background-color: #EA5506;
    width: 100%;
    display: block;
    position: relative;
    font-size: 20px;
    line-height: 72px;
    text-align: center;
    padding: 0 20px;
    border-radius: 12px;
    text-decoration: none !important;
}

.btn-signup > a:hover {
    color: #ffffff;
}


.btn-signup > a::after{
    background: url('../assets/do-to-win/icon_right.svg') no-repeat right center;
    background-size: 100% auto;
    content: "";
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 10px;
    left: 0;
    transition: all 0.1s ease-out;
}
.btn-signup > a:hover::after{
    left: 10px;
    transition: all 0.1s ease-out;
}


.block-bg-gray {
    background-color: #F8FAFC;
    padding: 60px;
    border-radius: 40px;
    overflow: hidden;
}


.block-conditions .block-title {
    font-size: 46px;
    font-weight: 600;
    margin-bottom: 24px;
    font-family: exo_bold;
}

.block-conditions .block-num-list {
    margin-top: 24px;
    counter-reset: num;
    position: relative;
    padding-left: 0;
}

.block-conditions .block-num-list > li + li {
    margin-top: 28px;
}

.block-conditions ul, ol {
    list-style: none;
}

.block-conditions .block-num-list::before {
    background-color: #EEB84B;
    content: "";
    width: 6px;
    height: calc(100% - 1em);
    position: absolute;
    z-index: 1;
    left: 13px;
}

.block-conditions .block-num-list > li {
    position: relative;
    padding-left: 30px;
    line-height: 1.5em;
    padding: 0 0.5em 0.5em 38px;
    position: relative;
    z-index: 2;
}

.block-conditions .block-num-list > li:before {
    position: absolute;
    counter-increment: num;
    content: counter(num, decimal-leading-zero);
    display: inline-block;
    color: #000000;
    background-color: #EEB84B;
    font-weight: normal;
    font-size: 10px;
    border-radius: 50%;
    left: 0;
    width: 32px;
    height: 32px;
    line-height: 24px;
    text-align: center;
    background-clip: padding-box;
    border: 4px solid #EEB84B33;
    top: -0.5em;
}

.block-bg-border {
    background-color: #FBFDFF;
    border: 20px solid #F8FAFB;
    border-radius: 40px;
    padding: 20px;
    overflow: hidden;
    position:relative;
}



.block-bg-border article, aside, canvas, figure, figcaption, footer, header, nav, section, audio, video, p, h1, h2, h3, h4, h5, h6, ul, ol {
    margin: 0;
    padding: 0;
    border: 0;
}

.block-wallet-text {
    font-size: 20px;
}

.block-wallet-text .strong-text {
    color: #F8B629;
    font-size: 24px;
    font-weight: 600px;
}
.block-wallet-text a, label, span {
    display: inline-block;
    vertical-align: middle;
    vertical-align: 0;
}

.block-wallets {
    display: flex;
    width: 100%;
    gap: 20px;
    padding-left: 0;
}

.block-wallets > * {
    width: calc(50% - 10px);
    display: flex;
}

.block-wallets > * > a:hover {
    opacity: 0.5;
    transition: all 0.3s ease-out;
    color: #111111;
}

.block-wallets ul, ol {
    list-style: none;
}

.block-wallets > * > a {
    color: #111111;
    background-color: #F5F7F9;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 19px;
    padding: 10px;
    gap: 10px;
    font-weight: bold;
    position: relative;
    transition: all 0.3s ease-out;
    text-decoration: none !important;
    font-family: exo_bold;
}

.block-wallets > * > a.btn-metamask::before {
    background-image: url('../assets/do-to-win/icon_metamask.svg');
}
.block-wallets > * > a::before {
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background: url('../assets/do-to-win/icon_metamask.svg') no-repeat center center;
    background-size: 100% auto;
    width: 60px;
    height: 60px;
    display: block;
    position: relative;
}

.block-wallets > * > a.btn-trust::before {
    background-image: url('../assets/do-to-win/icon_trust.svg');
}

.block-bg-border > * + * {
    margin-top: 24px;
}

.block-info-label {
    color: #ffffff;
    background-color: #F8B629;
    font-size: 20px;
    font-weight: 600;
    line-height: 51px;
    border-radius: 8px;
    padding: 0 17px;
    font-family: exo_bold;
}

.block-info-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 17px;
    gap: 16px;
    font-family: exo_bold;
}

.block-info-list > dt {
    width: calc(52% - 16px);
    font-size: 16px;
    position: relative;
    padding-left: 1.3em;
}

.block-info-list > dt::before {
    color: rgb(209, 124, 66);
    font-size: 10px;
    font-weight: bold;
    content: "●";
    position: relative;
    margin-right: -1.1em;
    left: -2.2em;
}

.block-info-list > dd {
    color: #111111;
    width: 48%;
    flex: 1;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    margin: 0;
}

@media (max-width: 767px){
    #mainvisual {
        height: auto;
        margin-bottom: 8vw;
    }
    #mainvisual::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vw;
        background-image: url('../assets/do-to-win/bg_mv_sp.png');
        background-position: top center;
    }
    
    #mainvisual .mainvisual-inner {
        padding: 100vw 5.333vw 0;
    }
    
    #mainvisual .mainvisual-inner .mainvisual-text-area {
        width: auto;
        max-width: 100%;
        padding-top: 0;
    }
    
    #mainvisual .mainvisual-text-area #logo {
        width: 31.2vw;
        max-width: 240px;
        margin-bottom: 2.133333vw;
    }
    #mainvisual .mainvisual-text-area h1 {
        font-size: 10.666667vw;
        margin-bottom: 1.066667vw;
    }
    #mainvisual .mainvisual-text-area .block-lead-text {
        font-size: 5.333333vw;
        margin-top: 1.6vw;
    }
    #mainvisual .mainvisual-text-area .block-lead-text .block-sub-lead-text {
        font-size: 4.266667vw;
    }

    #mainvisual .mainvisual-text-area .btn-signup {
        max-width: 100%;
        margin-top: 9.6vw;
    }    
    
    .section-inner {
        padding: 0 5.333333vw;
    }
    
    .section-inner > * + * {
        margin-top: 5.333333vw;
    }
    
    
    .box-grid {
        grid-template-columns: 100%;
        gap: 3.733333vw;
    }
    .box-grid > *:first-child {
        grid-row: 1;
    }

    .btn-signup {
        
    }
    .btn-signup > a{
        font-size: 5.333333vw;
        line-height: 15.466667vw;
        padding: 0 5.333333vw;
        border-radius: 3.2vw;
    }
    .btn-signup > a::after{
        width: 3.733333vw;
        height: 3.733333vw;
        margin-left: 2.666667vw;
    }
    .btn-signup > a:hover::after{
        left: 2.666667vw;
    }
    
    .block-bg-gray {
        padding: 5.333333vw 4.266667vw;
        border-radius: 5.333333vw;
    }
    
    .block-bg-border {
        border: none;
        border-radius: 5.333333vw;
        padding: 1.6vw;
    }
    
    .block-bg-border > * + * {
        margin-top: 4.266667vw;
    }
    
    .block-conditions .block-title {
        font-size: 4.8vw;
        margin-bottom: 3.733333vw;
    }
    .block-conditions .block-num-list {
        margin-top: 3.733333vw;
    }
    .block-conditions .block-num-list::before {
        width: 1.6vw;
        height: 60vw;
        left: 2.4vw;
        top: 1.2vw;
    }
    
    .block-conditions .block-num-list > li {
        font-size: 3.733333vw;
        padding: 0 0.5em 0.5em 8.533333vw;
    }
    .block-conditions .block-num-list > li + li {
        margin-top: 3.2vw;
    }
    .block-conditions .block-num-list > li:before {
        font-size: 1.706667vw;
        width: 6.4vw;
        height: 6.4vw;
        line-height: 5.066667vw;
        border-width: 0.8vw;
    }
    
    .block-wallet-text {
        font-size: 3.733333vw;
    }
    .block-wallet-text .strong-text {
        font-size: 4.26667vw;
    }
    .block-wallets {
        gap: 1.066667vw;
    }
    .block-wallets > * {
        width: calc(50% - 0.533333vw);
    }
    .block-wallets > * > a {
        font-size: 3.733333vw;
        border-radius: 2.666667vw;
        padding: 4.8vw;
        gap: 2.133333vw;
    }
    .block-wallets > * > a::before {
        width: 11.2vw;
        height: 11.2vw;
    }
    
    .block-info-label {
        font-size: 3.733333vw;
        line-height: 10.666667vw;
        border-radius: 2.133333vw;
        padding: 0 3.2vw;
    }
    .block-info-list {
        padding: 0 3.2vw;
        gap: 1.333333vw;
    }
    .block-info-list > dt {
        width: calc(52% - 1.333333vw);
        font-size: 3.2vw;
    }
    .block-info-list > dt::before {
        font-size: 1.6vw;
        margin-right: -1.1em;
        left: -2.2em;
    }
    .block-info-list > dd {
        font-size: 4.266667vw;
    }
}

@media (max-width: 1299px) {
    .block-bg-gray {
        padding: 60px 30px;
    }
    .block-conditions .block-title {
        font-size: 40px;
    }
    .block-bg-border {
        border: 20px solid #F8FAFB;
        padding: 20px;
    }
    .block-wallet-text {
        font-size: 18px;
    }
    .block-wallet-text .strong-text {
        font-size: 20px;
    }
    .block-info-label {
        font-size: 18px;
    }
    .block-info-list > dt {
        font-size: 14px;
    }
    .block-info-list > dd {
        font-size: 16px;
    }
}

@media (max-width: 991px) {
    .block-conditions .block-title {
        font-size: 46px;
        font-weight: 600;
        margin-bottom: 24px;
    }
}